import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Modal,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  InputAdornment,
  Paper,
  TableContainer,
  IconButton,
  Pagination,
  Popper,
  FormControlLabel

} from "@mui/material";
import api from '../../service/api';
import { Edit as EditIcon } from '@mui/icons-material';
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "../../components/MDBox";
import { CSVLink } from "react-csv";
import SearchIcon from "@mui/icons-material/Search";
import Tooltip from '@mui/material/Tooltip';
import { ToastContainer, toast } from 'react-toastify';
import { FaShippingFast } from "react-icons/fa";
import { TbListDetails } from "react-icons/tb";
import CloseIcon from '@mui/icons-material/Close';
import { BiEdit } from 'react-icons/bi';
import { Swiper, SwiperSlide } from 'swiper/react';
import { LuFilterX } from "react-icons/lu";
import Footer from "layouts/authentication/components/Footer";
import moment from 'moment';
import Cookies from 'js-cookie';
import Checkbox from '@mui/material/Checkbox';

const CompletedOrder = () => {
  const [orders, setOrders] = useState([]);

  const [centerList, setCenterList] = useState([]);
  const [orderDetails, setOrderDetails] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDispatchModalOpen, setIsDispatchModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortOption, setSortOption] = useState('');
  const [sortOrder, setSortOrder] = useState('');
  const [sortOrderDate, setSortOrderDate] = useState('default'); // default, asc, desc
  const [sortOrderName, setSortOrderName] = useState('default'); // default, asc, desc
  const theme = useTheme();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [selectedOrder, setSelectedOrder] = useState({
    id: null,
    trackingId: "",
    shippingCost: "",
    // other fields as needed
  });
  const [selectedDeliveryProvider, setSelectedDeliveryProvider] = useState(null);
  const fetchOrderse = async () => {
    try {
      const response = await api.get("/OrderByShipping", { headers: { "Authorization": `Bearer ${localStorage.getItem("token")}` } });
      setOrders(response.data);
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };


  useEffect(() => {
    async function fetchCenterList() {
      try {
        const response = await api.get("/CenterList", { headers: { "Authorization": `Bearer ${localStorage.getItem("token")}` } });
        setCenterList(response.data);
      } catch (error) {
        console.error("Error fetching centers:", error);
      }
    }
    fetchCenterList();
  }, []);

  const toggleModal = async (order) => {
    setSelectedOrder(order);
    setIsModalOpen(!isModalOpen);
    if (!isModalOpen) {
      try {
        const response = await api.post(
          `/OrderDetailsbyOrderId?orderId=${order.id}`,
          {}, // Pass an empty object for the request body if there is no body
          {
            headers: {
              "Authorization": `Bearer ${localStorage.getItem("token")}`
            }
          }
        );
        setOrderDetails(response.data);
      } catch (error) {
        console.error("Error fetching order details:", error);
      }
    }
  };

  useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isModalOpen]);

  const sortOrders = (a, b) => {
    if (sortOrder === "asc") {
      return a.fullName.localeCompare(b.fullName);
    } else if (sortOrder === "desc") {
      return b.fullName.localeCompare(a.fullName);
    } else {
      return new Date(b.CreatedAt) - new Date(a.CreatedAt);
    }
  };
  const sortOrdersByOrderDate = (a, b) => {
    if (sortOrderDate === 'asc') {
      return new Date(a.orderDate) - new Date(b.orderDate);
    } else if (sortOrderDate === 'desc') {
      return new Date(b.orderDate) - new Date(a.orderDate);
    }
    // Default sorting order (descending by CreatedAt)
    return new Date(b.CreatedAt) - new Date(a.CreatedAt);
  };
  const sortOrdersByOrderName = (a, b) => {
    if (sortOrderName === 'asc') {
      return a.orderName.localeCompare(b.orderName);
    } else if (sortOrderName === 'desc') {
      return b.orderName.localeCompare(a.orderName);
    }
    // Default sorting order (descending by CreatedAt)
    return new Date(b.CreatedAt) - new Date(a.CreatedAt);
  };

  const filteredOrders = orders
    .filter(order =>
      order.fullName.toLowerCase().toString().includes(searchQuery.toLowerCase().toString()) ||
      order.orderName && order.orderName.toLowerCase().toString().includes(searchQuery.toLowerCase().toString()) ||
      order.trackingId && order.trackingId.toLowerCase().toString().includes(searchQuery.toLowerCase().toString()) ||
      order.email && order.email.toLowerCase().toString().includes(searchQuery.toLowerCase()) ||
      order.phoneNo && order.phoneNo.toString().includes(searchQuery.toString()) ||
      order.fullAddress && order.fullAddress.toLowerCase().toString().includes(searchQuery.toLowerCase().toString()) ||
      order.deliveryProvider && order.deliveryProvider.includes(searchQuery.toString()) ||
      order.shopifyOrderId && order.shopifyOrderId.toString().includes(searchQuery.toString()) ||
      order.dispatch_Date && order.dispatch_Date.toString().includes(searchQuery.toString())

    )
    .sort(sortOrders)
    .sort(sortOrdersByOrderDate)
    .sort(sortOrdersByOrderName);

  const clearSortOrder = () => {
    setSearchQuery('');
    setSortOrder("");
    setSortOption("");
    setSortOrderDate("");
    setSortOrderName(""); // Reset sorting order to default
    Cookies.remove('sortOptionse'); // Clear the cookie
    Cookies.remove('searchCompletedOrder'); // Clear the cookie
    Cookies.remove('searchbyDate');
    setChecked(true);
    Cookies.remove('startDate');
    Cookies.remove('endDate');
    setOrderStartDate(firstDayOfMonth);
    setOrderEndDate(currentDate);
  };

  const toggleSortOrder = () => {
    setSortOrder((prevOrder) => {
      if (prevOrder === "asc") {
        return "desc";
      } else if (prevOrder === "desc") {
        return "default";
      } else {
        return "asc";
      }
    });
  };


  //const currentOrders = filteredOrders.filter((order) => order.shipped_Flag === true ,(order) => order.shipped_Flag === null);
  //const currentOrders = filteredOrders;


  const allOrdersCsvData = filteredOrders
    .map((order) => {
      const orderPrice = parseFloat((order.orderPrice - (order.totalDiscount + order.totalRefund))) || 0; // Convert to float or default to 0
      const shopifyShippingcost = parseFloat(order.shopifyShippingcost) || 0; // Convert to float or default to 0
      const shopifyTipAmount = parseFloat(order.shopifyTipAmount) || 0; // Convert to float or default to 0
      const purchasePrice = parseFloat(order.purchasePrice) || 0; // Convert to float or default to 0
      const shippingCost = parseFloat(order.shippingCost) || 0; // Convert to float or default to 0

      const profit = (
        (order.orderPrice - (order.totalDiscount + order.totalRefund)) -
        (order.purchasePrice + order.shippingCost) +
        (order.shopifyShippingcost + order.shopifyTipAmount)
      );

      return {
        OrderNumber: order.orderName,
        FullName: order.fullName,
        TotalWeight: order.totalWeight,
        OrderDate: order.orderDate,
        DispatchDate: order.dispatch_Date,
        SellingPrice: `₹${orderPrice.toFixed(2)}`, // Format to 2 decimal places
        shopifyShippingcost: `₹${shopifyShippingcost.toFixed(2)}`, // Format to 2 decimal places
        shopifyTipAmount: `₹${shopifyTipAmount.toFixed(2)}`, // Format to 2 decimal places
        COGS: `₹${purchasePrice.toFixed(2)}`, // Format to 2 decimal places
        ShippingCost: `₹${shippingCost.toFixed(2)}`, // Format to 2 decimal places
        Delivery: order.deliveryProvider,
        Tracking: `${order.trackingId}`,
        Address: `${order.fullAddress}`,
        Profit: `₹${profit.toFixed(2)}`, // Format profit to 2 decimal places
      };
    });

  const generateOrderDetailsCsvData = () => {
    return orderDetails.map((orderDetail) => {
      const totalWeight = orderDetail.purchasedQuantity * orderDetail.weightInGrams;
      const totalCost = orderDetail.purchasedQuantity * orderDetail.pricePerQty || 0;

      return {
        ProductName: orderDetail.productName,
        Quantity: orderDetail.quantity,
        PurchasedQuantity: orderDetail.purchasedQuantity,
        WeightInGrams: orderDetail.weightInGrams,
        PricePerQty: `₹${orderDetail.pricePerQty?.toFixed(2) || "0.00"}`,
        TotalWeight: `${totalWeight.toFixed(2)} gm`,
        TotalCost: `₹${totalCost.toFixed(2)}`,
        Status: orderDetail.is_Purchased
          ? "Purchased"
          : orderDetail.notAvailableQty !== null
            ? `N/A ${orderDetail.notAvailableQty} Qty`
            : "Pending",
      };
    });
  };

  useEffect(() => {
    const savedSortOption = Cookies.get('sortOptionse');
    if (savedSortOption) {
      setSortOption(savedSortOption);
      // Initialize sort order based on saved sort option
      handleSortChange({ target: { value: savedSortOption } });
    }
  }, []);

  const handleSortChange = (event) => {
    const value = event.target.value;
    setSortOption(value);
    Cookies.set('sortOptionse', value, { expires: 7 }); // Cookie expires in 7 days

    // Call appropriate sorting function based on selected option
    if (value === 'AtoZ') {
      setSortOrder('asc');
    } else if (value === 'ZtoA') {
      setSortOrder('desc');
    } else if (value === 'Oldest') {
      setSortOrderDate('asc');
    } else if (value === 'Newest') {
      setSortOrderDate('desc');
    } else if (value === 'Order No.: Asc') {
      setSortOrderName('asc');
    } else if (value === 'Order No.: Des') {
      setSortOrderName('desc');
    }
  };

  const handleDispatchOrder = async (e) => {
    e.preventDefault(); // Prevent form default submission
    if (selectedOrder) {
      try {
        // First, update the shipping order status
        const data = {
          id: selectedOrder.id,
          shopifyDeliveryService: dispatchData.deliveryProvider,
          trackingId: dispatchData.trackingId,
          shippingCost: dispatchData.shippingCost,
          note: dispatchData.note,
        };

        const response = await api.post("/UpdateShippingOrder", data, { headers: { "Authorization": `Bearer ${localStorage.getItem("token")}` } });
        console.log("Order dispatched:", response.data);

        toast.success("Updated Shipping Data Successfully!");

        // // Handle success
        setIsDispatchModalOpen(false);
        // refreshOrderList();
        fetchOrders();


      } catch (error) {
        toast.error("Error Updating Shipping Data");
        console.error("Error dispatching order:", error);
      }
    } else {
      console.error("Error: Selected order or delivery provider is null");
    }
  };


  // Set current date in yyyy-mm-dd format
  const currentDate = new Date().toISOString().split('T')[0];
  // Extract the year and month from the current date string
  const [year, month] = currentDate.split('-');


  const firstDayOfMonth = `${year}-${month}-01`;

  // Initialize state with default values or from cookies
  const [orderStartDate, setOrderStartDate] = useState(firstDayOfMonth);
  const [orderEndDate, setOrderEndDate] = useState(currentDate);

  useEffect(() => {
    const savedStartDate = Cookies.get('startDate') || firstDayOfMonth;
    const savedEndDate = Cookies.get('endDate') || currentDate;
    setOrderStartDate(savedStartDate);
    setOrderEndDate(savedEndDate);
  }, [firstDayOfMonth, currentDate]);

  // Handle start date change
  const handleInputStartDateChange = (event) => {
    const newStartDate = event.target.value;
    setOrderStartDate(newStartDate);
    Cookies.set('startDate', newStartDate, { expires: 7 }); // Set cookie for 7 days
  };

  // Handle end date change
  const handleInputEndDateChange = (event) => {
    const newEndDate = event.target.value;
    setOrderEndDate(newEndDate);
    Cookies.set('endDate', newEndDate, { expires: 7 }); // Set cookie for 7 days
  };

  const fetchOrders = async () => {
    // if (!orderStartDate || !orderEndDate) {
    //   toast.error('Both start and end dates are required!');
    //   return;
    // }

    try {
      const response = await api.get(`/OrderByShipping?SDate=${orderStartDate}&EDate=${orderEndDate}&dateFlag=${checked}&searchString=${searchQuery}`, {
        headers: {
          "Authorization": `Bearer ${localStorage.getItem("token")}`
        }
      });

      setOrders(response.data);
    } catch (error) {
      console.error("Error refreshing order list:", error);
    }
  };

  const [errors, setErrors] = useState({
    shippingCost: false,
    trackingId: false,
    note: false,
  });


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setDispatchData((prevData) => ({ ...prevData, [name]: value }));
  };

  const toggleDispatchModal = (order) => {
    setSelectedOrder(order); // Set the selected order
    setSelectedDeliveryProvider(""); // Reset selected center

    const formattedShippingCost = order.shippingCost;

    setDispatchData({
      deliveryProvider: order.deliveryProvider,
      trackingId: order.trackingId,
      shippingCost: formattedShippingCost,
      note: order.note,
    });
    setIsDispatchModalOpen(!isDispatchModalOpen); // Toggle modal
  };

  const [dispatchData, setDispatchData] = useState({
    shopifyDeliveryService: "",
    trackingId: "",
    shippingCost: "",
    note: "",
  });

  // State for pagination
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);


  // // Pagination
  // const handleChangePage = (event, newPage) => {
  //   setCurrentPage(newPage);
  // };

  // Pagination logic
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  // Update current page if it exceeds the number of pages after filtering
  const pageCount = Math.ceil(filteredOrders.length / itemsPerPage);
  if (currentPage > pageCount && pageCount > 0) {
    setCurrentPage(pageCount);
  }

  const currentOrders = filteredOrders.slice(startIndex, endIndex);

  const handleChangePage = (event, value) => {
    setCurrentPage(value);
  };


  // Calculate the total number of pages
  //const pageCount = Math.ceil(currentOrders.length / itemsPerPage);

  // Get the vendors for the current page
  const displayOrders = currentOrders.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );


  useEffect(() => {
    // Retrieve cookie value on component mount
    const savedQuery = Cookies.get('searchCompletedOrder');
    if (savedQuery) {
      setSearchQuery(savedQuery);
    }
  }, []);

  const [checked, setChecked] = useState(true); // initial state

  const handleSearchChange = (event) => {
    const newSearchQuery = event.target.value;
    setSearchQuery(newSearchQuery);
    // Set cookie with the new search query
    Cookies.set('searchCompletedOrder', newSearchQuery, { expires: 7 }); // Expires in 7 days
  };

  useEffect(() => {
    // Retrieve cookie value on component mount
    const savedQuery = Cookies.get('searchbyDate');
    if (savedQuery !== undefined) {
      setChecked(savedQuery === 'true'); // Convert string to boolean
    }
  }, []);

  const handleChangeCheckbox = (event) => {
    const isChecked = event.target.checked;
    setChecked(isChecked); // Get the checked state
    Cookies.set('searchbyDate', isChecked.toString(), { expires: 7 }); // Save as a string
  };

  useEffect(() => {
    fetchOrders();
  }, [sortOption, checked]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <ToastContainer
        style={{
          position: isMobile ? 'top-right' : 'top-right',
          width: isMobile ? '80%' : '25%',
          fontSize: isMobile ? '12px' : '15px'
        }}
      />
      {/* <MDBox py={3} px={2} variant="gradient" bgColor="info" borderRadius="lg" coloredShadow="info">
        <Typography variant="h5" style={{ color: "white" }} component="h2">
          Shipped Order
        </Typography>
      </MDBox> */}
      <Box sx={{ display: "flex", flexDirection: isMobile ? "column" : "row", alignItems: "center", mt: 2 }}>
        <TextField
          placeholder="Search"
          variant="outlined"
          size="medium"
          value={searchQuery}
          onChange={handleSearchChange}
          //onChange={(e) => setSearchQuery(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" style={{ fontSize: '20px' }}>
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          sx={{
            width: isMobile ? "100%" : "auto",
            maxWidth: 240,
            margin: isMobile ? "8px 0" : "4px",
            "& .MuiOutlinedInput-root": {
              borderRadius: '0.375rem',
              "&.Mui-focused fieldset": {
                borderColor: "#3e3e45",
              },
            },
            "& .MuiInputLabel-root": {
              fontSize: "1rem",
            },
          }}
        />
        <FormControl sx={{
          width: isMobile ? "100%" : "auto", maxWidth: 150, margin: isMobile ? "8px 0" : "4px",
          '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
              borderColor: '#3e3e45', // Outline color when focused
            },
          },
        }}>
          <InputLabel id="sort-label" style={{ color: '#3e3e45' }}>Sort by</InputLabel>
          <Select
            labelId="sort-label"
            value={sortOption}
            label="Sort By"
            onChange={handleSortChange}
            sx={{ width: isMobile ? "100%" : "155px", borderRadius: '0.375rem', padding: "10px 0px", marginBottom: isMobile ? "0px" : "0" }}
          >
            <MenuItem value="">
              <em>Sort by</em>
            </MenuItem>
            <MenuItem value="AtoZ">AtoZ</MenuItem>
            <MenuItem value="ZtoA">ZtoA</MenuItem>
            <MenuItem value="Newest">Newest</MenuItem>
            <MenuItem value="Oldest">Oldest</MenuItem>
            <MenuItem value="Order No.: Asc">OrderNo. : Asc</MenuItem>
            <MenuItem value="Order No.: Des">OrderNo. : Des</MenuItem>
          </Select>
        </FormControl>
        <FormControlLabel
          sx={{ fontSize: '15px', marginLeft: '10px' }}
          style={{
            fontWeight: '300'
          }}
          label="Include Date"
          control={
            <Checkbox
              checked={checked}
              onChange={handleChangeCheckbox}
            />
          }
        />

        {/* <Checkbox
          defaultChecked={true}
          onChange={handleChangeCheckbox}
          inputProps={{ 'aria-label': 'controlled' }}
        /> */}

        {/* <Checkbox
          label="Include Date"
          checked={checked}
          onChange={handleChangeCheckbox}
        /> */}
        <Box display="flex" flexDirection="row" alignItems="center" p={2}>
          <TextField
            type="date"
            label="Start Date"
            required
            value={orderStartDate}
            onChange={handleInputStartDateChange}
            variant="outlined"
            sx={{
              width: 'fit-content',
              borderRadius: '20px',
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#000',
                },
                '&:hover fieldset': {
                  borderColor: '#000',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#000',
                },
              },
            }}
            InputLabelProps={{
              style: { color: 'black' },
              shrink: true,
            }}
          />
          <TextField
            type="date"
            label="End Date"
            required
            value={orderEndDate}
            onChange={handleInputEndDateChange}
            variant="outlined"
            sx={{
              marginLeft: '10px',
              width: 'fit-content',
              borderRadius: '0.375rem',
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#000',
                },
                '&:hover fieldset': {
                  borderColor: '#000',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#000',
                },
              },
            }}
            InputLabelProps={{
              style: { color: 'black' },
              shrink: true,
            }}
          />
          {/* <Button
        variant="contained"
        onClick={fetchOrders}
        style={{
          marginLeft: '10px', color: 'white', backgroundColor: '#3e3e45'
        }}
      >
        Search
      </Button> */}
        </Box>
        <Button
          variant="contained"
          onClick={fetchOrders}

          style={{
            marginLeft: '10px', color: 'white', fontWeight: '400', textTransform: 'none', backgroundColor: '#3e3e45'
          }}
        >
          Search
        </Button>
        <Button
          style={{ color: "white", backgroundColor: "green" }}
          className="btn btn-success"
          variant="contained"
          sx={{ marginLeft: isMobile ? "0" : "auto", marginTop: isMobile ? "10px" : "0", marginBottom: isMobile ? "10px" : "0", width: isMobile ? "40%" : "auto" }}
        >
          <CSVLink data={allOrdersCsvData} filename={"shipped_order.csv"} style={{ textDecoration: "none", color: "white" }}>
            Export to Excel
          </CSVLink>
        </Button>
        <Tooltip title="Clear">
          <Button
            onClick={clearSortOrder}
            variant="contained"
            style={{ color: 'white', backgroundColor: '#3e3e45', marginLeft: isMobile ? '0' : '10px', }}
          // sx={{
          //   marginLeft: isMobile ? '0' : 'auto',
          //   marginTop: isMobile ? '10px' : '0',
          //   marginBottom: isMobile ? '10px' : '0',
          //   width: isMobile ? '40%' : 'auto',
          // }}
          >
            <LuFilterX style={{ fontSize: '20px' }} />
          </Button>
        </Tooltip>

      </Box>
      {/* <Box sx={{ mt: 2 }}> */}

      {isMobile ? (
        currentOrders.map((order, index) => (
          <Card
            sx={{
              // backgroundColor: 'white',
              background: order && order.shippingCost !== null
                      ? 'white'
                      : 'lightpink',
              border: '0px solid black',
              margin: '15px',
              borderRadius: '10px',
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
              transition: 'transform 0.2s ease-in-out',
              '&:hover': {
                transform: 'scale(1.02)',
                boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)',
              },
            }}
            key={index}
          >
            <CardContent>
              <Box>
                <strong style={{ marginRight: '16px' }}>Name:</strong>
                <span>{order.fullName}</span>
              </Box>
              <Box>
                <strong style={{ marginRight: '16px' }}>Order No:</strong>
                <span>{order.orderName}</span>
              </Box>
              <Box>
                <strong style={{ marginRight: '16px' }}>Total Weight:</strong>
                <span>{order.totalWeight}<span style={{ fontSize: 'medium' }}>gm</span></span>
              </Box>
              <Box>
                <strong style={{ marginRight: '16px' }}>Selling Price:</strong>
                <span>₹{(order.orderPrice - (order.totalDiscount + order.totalRefund)).toFixed(2)}</span>
              </Box>
              <Box>
                <strong style={{ marginRight: '16px' }}>Shopify Shipping Cost:</strong>
                <span>₹{(order.shopifyShippingcost || 0).toFixed(2)}</span>
              </Box>
              <Box>
                <strong style={{ marginRight: '16px' }}>Shopify Tip:</strong>
                <span>₹{(order.shopifyTipAmount || 0).toFixed(2)}</span>
              </Box>
              <Box>
                <strong style={{ marginRight: '16px' }}>COGS:</strong>
                <span>₹{(order.purchasePrice || 0).toFixed(2)}</span>
              </Box>
              <Box>
                <strong style={{ marginRight: '8px' }}>Shipping Cost:</strong>
                <span>₹{(order.shippingCost || 0).toFixed(2)}</span>
              </Box>
              <Box>
                <strong style={{ marginRight: '24px' }}>Tracking Id:</strong>
                <span>{order.trackingId}</span>
              </Box>
              <Box>
                <strong style={{ marginRight: '24px' }}>Address:</strong>
                <span>{order.fullAddress}</span>
              </Box>
              <Box>
                <strong style={{ marginRight: '24px' }}>Delivery:</strong>
                <span>{order.deliveryProvider}</span>
              </Box>
              <Box>
                <strong style={{ marginRight: '16px' }}>Profit:</strong>
                <span>₹{(
                  (order.orderPrice - (order.totalDiscount + order.totalRefund)) -
                  (order.purchasePrice + order.shippingCost) +
                  (order.shopifyShippingcost + order.shopifyTipAmount)
                ).toFixed(2)}</span>
              </Box>
              <Box>
                <strong style={{ marginRight: '24px' }}>Order Date:</strong>
                <span>{moment(order.orderDate).format('lll')}</span>
              </Box>
              <Box>
                <strong style={{ marginRight: '24px' }}>Dispatch Date:</strong>
                <span>{order.dispatch_Date ? moment(order.dispatch_Date).format('ll') : '-'}</span>
              </Box>
              <Box >
                <strong style={{ marginRight: '16px' }}>Action:</strong>
                <IconButton
                  style={{ color: 'black', marginLeft: '100px' }}
                  onClick={() => toggleModal(order)}
                >
                  <TbListDetails />
                </IconButton>
                <Tooltip title="Update Order">
                  <IconButton

                    style={{ color: 'green', marginLeft: '0px', textAlign: 'end' }}
                    onClick={() => toggleDispatchModal(order)}
                  >
                    <BiEdit />
                  </IconButton>
                </Tooltip>
              </Box>
            </CardContent>
          </Card>
        ))
      ) : (
        <>
          <TableContainer component={Paper} sx={{ maxHeight: 800, overflow: 'auto', scrollbarWidth: 'none' }}>
            <Table stickyHeader>
              <TableBody>
                <TableRow sx={{ position: 'sticky', zIndex: 1, top: 0, backgroundColor: '#3e3e45', color: 'white' }}>
                  <TableCell style={{ fontWeight: '500', color: 'white', marginLeft: '0px', marginRight: '0px' }}>Sr No</TableCell>
                  <TableCell style={{ fontWeight: '500', color: 'white' }}>Full Name</TableCell>
                  <TableCell align="center" style={{ fontWeight: '500', color: 'white' }}>Order No</TableCell>
                  <TableCell align="center" style={{ fontWeight: '500', color: 'white' }}>Order Date</TableCell>
                  <TableCell align="center" style={{ fontWeight: '500', color: 'white' }}>Dispatch Date</TableCell>
                  <TableCell align="center" style={{ fontWeight: '500', color: 'white' }}>Selling Price</TableCell>
                  <TableCell align="center" style={{ fontWeight: '500', color: 'white' }}>Shopify Shippingcost</TableCell>
                  <TableCell align="center" style={{ fontWeight: '500', color: 'white' }}>Shopify Tip</TableCell>
                  <TableCell align="center" style={{ fontWeight: '500', color: 'white' }}>COGS</TableCell>
                  <TableCell align="center" style={{ fontWeight: '500', color: 'white' }}>Shipping Cost</TableCell>
                  <TableCell align="center" style={{ fontWeight: '500', color: 'white' }}>Tracking Id</TableCell>
                  <TableCell align="center" style={{ fontWeight: '500', color: 'white' }}>Delivery</TableCell>
                  <TableCell align="center" style={{ fontWeight: '500', color: 'white' }}>Profit/Loss</TableCell>
                  <TableCell align="center" style={{ fontWeight: '500', color: 'white' }}>Actions</TableCell>
                </TableRow>
                {currentOrders.map((order, index) => (
                  <TableRow style={{
                    background: order && order.shippingCost !== null
                      ? 'white'
                      : 'lightpink'

                  }} key={index}>
                    <TableCell style={{ fontWeight: "400" }}>{(currentPage - 1) * itemsPerPage + index + 1}.</TableCell>
                    <TableCell style={{ fontWeight: "400" }}>
                      {order.fullName}
                    </TableCell >
                    <TableCell align="center" style={{ fontWeight: "400" }}>
                      {order.orderName}
                    </TableCell >
                    <TableCell align="center" style={{ fontWeight: "400" }}>
                      {moment(order.orderDate).format('ll')}
                    </TableCell >
                    <TableCell align="center" style={{ fontWeight: "400" }}>
                      {order.dispatch_Date ? moment(order.dispatch_Date).format('ll') : '-'}
                    </TableCell>
                    <TableCell style={{ fontWeight: "400" }} align="center">₹{(order.orderPrice - (order.totalDiscount + order.totalRefund)).toFixed(2)}</TableCell>
                    <TableCell style={{ fontWeight: "400" }} align="center">₹{(order.shopifyShippingcost || 0).toFixed(2)}</TableCell>
                    <TableCell style={{ fontWeight: "400" }} align="center">₹{(order.shopifyTipAmount || 0).toFixed(2)}</TableCell>
                    <TableCell style={{ fontWeight: "400" }} align="center">₹{(order.purchasePrice || 0).toFixed(2)}</TableCell>
                    <TableCell style={{ fontWeight: "400" }} align="center">₹{(order.shippingCost || 0).toFixed(2)}</TableCell>
                    <TableCell style={{ fontWeight: "400" }} align="center">{order.trackingId}</TableCell>
                    <TableCell style={{ fontWeight: "400" }} align="center">{order.deliveryProvider}</TableCell>
                    <TableCell style={{ fontWeight: "400" }} align="center">
                      ₹{(
                        (order.orderPrice - (order.totalDiscount + order.totalRefund)) -
                        (order.purchasePrice + order.shippingCost) +
                        (order.shopifyShippingcost + order.shopifyTipAmount)
                      ).toFixed(2)}
                    </TableCell>
                    <TableCell align="center">
                      <Tooltip title="View Details">
                        <IconButton

                          style={{ color: 'black', marginRight: '10px' }}
                          onClick={() => toggleModal(order)}
                        >
                          <TbListDetails />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Update Order">
                        <IconButton

                          style={{ color: 'green', textAlign: 'end' }}
                          onClick={() => toggleDispatchModal(order)}
                        >
                          <BiEdit />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
      {/* </Box> */}
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
        <Pagination
          count={pageCount}
          page={currentPage}
          onChange={handleChangePage}
          color='primary'
          sx={{
            '& .MuiPaginationItem-root.Mui-selected': {
              color: '#fff',
              background: 'linear-gradient(195deg, #42424a, #191919)',
            },
          }}
        />
      </Box>
      {/* <Pagination
          rowsPerPageOptions={[10, 25]}
          component="div"
          count={currentOrders.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
      {/* </Box > */}
      <Modal open={isDispatchModalOpen && !!selectedOrder} onClose={toggleDispatchModal}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: isMobile ? '90%' : 400,
            maxWidth: '100%',
            bgcolor: 'background.paper',
            boxShadow: 24,
            borderRadius: 4,
            p: 4,
            maxHeight: '80vh',
            overflowY: 'auto',
          }}
        >
          <IconButton
            sx={{ position: 'absolute', top: 8, right: 8 }}
            style={{ color: 'black', fontWeight: '500' }}
            onClick={toggleDispatchModal}
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h5" style={{ color: 'black', margin: '10px' }} align="center" sx={{ mb: 2 }}>
            Update Order
          </Typography>

          <form onSubmit={handleDispatchOrder}>
            <TextField
              label="Delivery Provider"
              name="deliveryProvider"
              value={dispatchData.deliveryProvider}
              onChange={handleInputChange}
              variant="outlined"
              fullWidth
              margin="normal"
            // required
            // error={!!errors.shopifyDeliveryService}
            // helperText={errors.shopifyDeliveryService}
            />
            <TextField
              label="Tracking Id"
              name="trackingId"
              value={dispatchData.trackingId}
              onChange={handleInputChange}
              variant="outlined"
              fullWidth
              margin="normal"
              required
              error={!!errors.trackingId}
              helperText={errors.trackingId}
            />
            <TextField
              label="Shipping Cost"
              name="shippingCost"
              value={dispatchData.shippingCost}
              onChange={handleInputChange}
              variant="outlined"
              fullWidth
              margin="normal"
              required
              error={!!errors.shippingCost}
              helperText={errors.shippingCost}
            />
            <TextField
              label="Note"
              name="note"
              value={dispatchData.note}
              onChange={handleInputChange}
              variant="outlined"
              fullWidth
              margin="normal"
              multiline
              rows={4} // Adjust the number of rows as needed
              error={!!errors.note}
              helperText={errors.note}
            />
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
              <Button
                variant="contained"
                style={{ color: "white", backgroundColor: '#212644fc', textAlign: 'end' }}
                type="submit"
              >
                Confirm
              </Button>
            </div>
          </form>
        </Box>
      </Modal>

      <Modal open={isModalOpen} onClose={toggleModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: isMobile ? '90%' : 1500,
            maxWidth: '100%',
            bgcolor: "background.paper",
            boxShadow: 24,
            borderRadius: 4,
            p: 2,
            maxHeight: isMobile ? '80vh' : '90vh',
            overflowY: "auto",
            scrollbarWidth: 'none'
          }}
        >
          <IconButton
            sx={{ position: 'absolute', top: 8, right: 8 }}
            style={{ color: 'black', fontWeight: '500' }}
            onClick={toggleModal}
          >
            <CloseIcon />
          </IconButton>
          <div style={{ display: 'flex', flexDirection: 'row' }} >
            <Typography variant="body1" style={{ margin: '5px', fontWeight: '500', fontSize: '25px' }} component="div">
              Order Details
              {isModalOpen && (
                <Typography>
                  Order No: {selectedOrder.orderName}
                </Typography>
              )}
              {isModalOpen && (
                <Typography>
                  Customer Name:{selectedOrder.fullName}
                </Typography>
              )}
            </Typography>
            <Typography variant="body1" style={{ marginLeft: '38px', marginTop: '44px', fontWeight: '500', fontSize: '20px' }} component="div">
              {isModalOpen && (
                <Typography>
                  Phone: {selectedOrder.phoneNo}
                </Typography>
              )}
              {isModalOpen && (
                <Typography>
                  Delivery Provider:{selectedOrder.deliveryProvider}
                </Typography>
              )}
            </Typography>
            <Typography variant="body1" style={{ marginLeft: '38px', marginTop: '44px', fontWeight: '500', fontSize: '20px' }} component="div">
              {isModalOpen && (
                <Typography>
                  Order Date: {moment(selectedOrder.orderDate).format('lll')}
                </Typography>
              )}
              {isModalOpen && (
                <Typography>
                  Dispatch Date:  {selectedOrder.dispatch_Date ? moment(selectedOrder.dispatch_Date).format('ll') : '-'}
                </Typography>
              )}
            </Typography>

            <Typography variant="body1" style={{ marginLeft: '38px', marginTop: '44px', fontWeight: '500', fontSize: '20px' }} component="div">
              {isModalOpen && (
                <Typography>
                  Address: {selectedOrder.fullAddress}
                </Typography>
              )}
              {isModalOpen && (
                <Typography>
                  Tracking Id: {selectedOrder.trackingId}
                </Typography>
              )}
            </Typography>
          </div>
          {isMobile ? (
            <Swiper spaceBetween={10} slidesPerView={1}>
              {orderDetails.map((orderDetail, index) => (
                <SwiperSlide key={index}>
                  <Box
                    sx={{
                      padding: 2,
                      border: '1px solid #ddd',
                      borderRadius: '10px',
                      mb: 2,
                      bgcolor: '#f9f9f9', // Light background for better contrast
                    }}
                  >
                    <Typography variant="h6" sx={{ mb: 1 }}>
                      Item No: {index + 1}
                    </Typography>

                    <Typography sx={{ mb: 1 }}>
                      <strong>Product:</strong> {orderDetail.productName}
                    </Typography>

                    <Typography sx={{ mb: 1 }}>
                      <strong>Quantity:</strong> {orderDetail.quantity}
                    </Typography>

                    <Typography sx={{ mb: 1 }}>
                      <strong>Purchased Quantity:</strong> {orderDetail.purchasedQuantity}
                    </Typography>

                    <Typography sx={{ mb: 1 }}>
                      <strong>Weight In Grams:</strong> {orderDetail.weightInGrams}
                    </Typography>

                    <Typography sx={{ mb: 1 }}>
                      <strong>Price Per Qty:</strong> ₹{orderDetail.pricePerQty || ""}
                    </Typography>

                    <Typography sx={{ mb: 1 }}>
                      <strong>Total Weight:</strong> {orderDetail.purchasedQuantity * orderDetail.weightInGrams} gm
                    </Typography>

                    <Typography sx={{ mb: 1 }}>
                      <strong>Total Cost:</strong> ₹{orderDetail.purchasedQuantity * orderDetail.pricePerQty}
                    </Typography>

                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: '8px 0',
                        borderBottom: '0px solid rgba(224, 224, 224, 1)',
                      }}
                    >
                      <Typography>
                        <strong>Status:</strong>
                      </Typography>
                      <Box
                        sx={{
                          borderRadius: '4px',
                          display: 'flex',
                          alignItems: 'center',
                          padding: '4px 8px',
                          fontSize: '12px',
                          color: orderDetail.isPurchased === null ? '#ff0000' : '#008000',
                          backgroundColor: orderDetail.isPurchased === null
                            ? 'rgba(255, 0, 0, 0.2)'
                            : 'rgba(0, 128, 0, 0.2)',
                        }}
                      >
                        <span
                          style={{
                            width: '4px',
                            height: '4px',
                            borderRadius: '50%',
                            marginRight: '4px',
                            backgroundColor: orderDetail.isPurchased === null ? '#ff0000' : '#008000',
                          }}
                        ></span>
                        {orderDetail.isPurchased === null ? "Pending" : "Purchased"}
                      </Box>
                    </Box>
                  </Box>
                </SwiperSlide>
              ))}
            </Swiper>
          ) : (
            <>
              <TableContainer component={Paper} sx={{ maxHeight: 600, overflow: 'auto', scrollbarWidth: 'none' }}>
                <Table stickyHeader>
                  <TableBody>
                    <TableRow sx={{ position: 'sticky', zIndex: 1, top: 0, backgroundColor: '#3e3e45', color: 'white' }}>
                      <TableCell style={{ fontWeight: "500", color: 'white' }}>Sr No</TableCell>
                      <TableCell style={{ fontWeight: "500", color: 'white' }}>Product</TableCell>
                      <TableCell style={{ fontWeight: "500", color: 'white' }} align="center">
                        Quantity
                      </TableCell>
                      <TableCell style={{ fontWeight: "500", color: 'white' }} align="center">
                        Purchased Quantity
                      </TableCell>
                      <TableCell style={{ fontWeight: "500", color: 'white' }} align="center">
                        Weight In Grams
                      </TableCell>
                      <TableCell style={{ fontWeight: '500', color: 'white' }} align="center">Price Per Qty</TableCell>
                      <TableCell style={{ fontWeight: "500", color: 'white' }} align="center">
                        Total Weight
                      </TableCell>
                      <TableCell style={{ fontWeight: "500", color: 'white' }} align="center">
                        Total Cost
                      </TableCell>
                      <TableCell style={{ fontWeight: "500", color: 'white' }} align="center">
                        Status
                      </TableCell>
                    </TableRow>
                    {orderDetails.map((orderDetail, index) => (
                      <TableRow key={index}>
                        <TableCell style={{ fontWeight: '500' }}> {index + 1}.</TableCell>
                        <TableCell style={{ fontWeight: '500' }}> {orderDetail.productName}</TableCell>
                        <TableCell style={{ fontWeight: '500' }} align="center">{orderDetail.quantity}</TableCell>
                        <TableCell style={{ fontWeight: '500' }} align="center">{orderDetail.purchasedQuantity}</TableCell>
                        <TableCell style={{ fontWeight: '500' }} align="center">{orderDetail.weightInGrams}</TableCell>
                        <TableCell style={{ fontWeight: '500' }} align="center"> {orderDetail.pricePerQty || ""}</TableCell>
                        <TableCell style={{ fontWeight: '500' }} align="center">{orderDetail.purchasedQuantity * orderDetail.weightInGrams}<span style={{ fontSize: 'medium' }}>gm</span></TableCell>
                        <TableCell style={{ fontWeight: '500' }} align="center">₹{orderDetail.purchasedQuantity * orderDetail.pricePerQty}</TableCell>
                        <TableCell align="center">
                          <TableCell
                            sx={{
                              width: 'max-content',
                              borderRadius: '4px',
                              display: 'flex',
                              alignItems: 'center',
                              padding: '4px 8px',
                              fontSize: '12px',
                              color: orderDetail.is_Purchased === true ? '#008000' : orderDetail.notAvailableQty !== null ? '#ff0000' : '#ff0000',
                              backgroundColor: orderDetail.is_Purchased === true ? 'rgba(0, 128, 0, 0.2)' : orderDetail.notAvailableQty !== null ? 'rgba(255, 0, 0, 0.2)' : 'rgba(255, 0, 0, 0.2)',
                            }}
                          >
                            <span
                              style={{
                                width: '4px',
                                height: '4px',
                                borderRadius: '50%',
                                marginRight: '4px',
                                backgroundColor: orderDetail.is_Purchased === true ? '#008000' : orderDetail.notAvailableQty !== null ? '#ff0000' : '#ff0000',
                              }}
                            ></span>
                            {orderDetail.is_Purchased === true ? "Purchased" : orderDetail.isNotAvailable === true ? "N/A" : orderDetail.notAvailableQty !== 0 ? `N/A ${orderDetail.notAvailableQty} Qty` : orderDetail.lock_Flag !== true ? "Pending" : ""}
                            {/* {orderDetail.is_Purchased === true ? "Purchased" : orderDetail.isNotAvailable === true ? "N/A" : orderDetail.notAvailableQty !== null ? `N/A ${orderDetail.notAvailableQty} Qty` : orderDetail.lock_Flag !== true ? "Pending" : ""} */}
                          </TableCell>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}

        </Box>
      </Modal>
      {/* <Footer dark /> */}
    </DashboardLayout >
  );
};

export default CompletedOrder;