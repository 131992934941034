import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  Grid,
  Modal,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Pagination,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControlLabel,
  Switch,
  InputAdornment,
  FormGroup,
  TableContainer,
  Paper
} from "@mui/material";
import api from "../../service/api";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import moment from 'moment';
import { FcViewDetails } from "react-icons/fc";
import { BiSolidLocationPlus } from "react-icons/bi";
import { Label } from "@mui/icons-material";
import { ToastContainer, toast } from 'react-toastify';
import MDBox from "../../components/MDBox"
import { FaSort } from "react-icons/fa";
import SearchIcon from '@mui/icons-material/Search';
import { IoMdCheckboxOutline } from "react-icons/io";
import { FaShippingFast } from "react-icons/fa";
import Tooltip from '@mui/material/Tooltip';
import { LuFilterX } from "react-icons/lu";
import CloseIcon from '@mui/icons-material/Close';
import Footer from "layouts/authentication/components/Footer";
import { Swiper, SwiperSlide } from 'swiper/react';
import '../../../node_modules/swiper/swiper-bundle.min.css';
import '../../../node_modules/swiper/swiper.min.css';
import Cookies from 'js-cookie';

const Orders = () => {
  const [orders, setOrders] = useState([]);
  const [CenterList, setCenterList] = useState([]);
  const [DeliveryList, setDeliveryList] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [orderDetails, setOrderDetails] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDispatchModalOpen, setIsDispatchModalOpen] = useState(false);
  const [isCenterModalOpen, setIsCenterModalOpen] = useState(false);
  const [selectedCenter, setSelectedCenter] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [sortOrder, setSortOrder] = useState(''); // Set default sorting order to descending
  const [sortOrderDate, setSortOrderDate] = useState('default'); // default, asc, desc
  const [sortOrderName, setSortOrderName] = useState('default'); // default, asc, desc
  const itemsPerPage = 12;
  const [localOrderDetails, setLocalOrderDetails] = useState([
    // Initial state with a sample structure
    { orderDetails }
    // add more items if necessary
  ]);
  const [openCloseModal, setOpenCloseModal] = useState(false);
  const [orderId, setOrderId] = useState('');
  const [showActiveUsers, setShowActiveUsers] = useState(true);
  const [sortOption, setSortOption] = useState('');
  const [filterStatus, setFilterStatus] = useState('');
  const [filterDispatch, setFilterDispatch] = useState('');
  const [editData, setEditData] = useState(null); // State to hold data for editing
  const [filterOption, setFilterOption] = useState('');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [selectedDeliveryService, setSelectedDeliveryService] = useState(null);

  const [formData, setFormData] = useState({
    id: '',
    purchasedQuantity: null,
    pricePerQty: null,
    weightPerQty: null,
    is_Purchased: null,
  });

  // const [dispatchData, setDispatchData] = useState({
  //   shopifyDeliveryService: "",
  //   trackingId: "",
  //   shippingCost: "",
  //   note: "",
  //   dispatch_Date: new Date().toISOString().split('T')[0], // Setting the current date
  // });

  const [dispatchData, setDispatchData] = useState({
    shopifyDeliveryService: "",
    trackingId: "",
    shippingCost: "",
    note: "",
    dispatch_Date: new Date().toISOString().split('T')[0], // Setting the current date
  });

  useEffect(() => {
    async function fetchOrders() {
      try {
        const response = await api.get("/OrderByCustomer", { headers: { "Authorization": `Bearer ${localStorage.getItem("token")}` } });
        console.log("Orders fetched: ", response.data); // Log the response data
        setOrders(response.data);
      } catch (error) {
        console.error("Error fetching orders:", error);
      }
    }
    fetchOrders();
  }, []);



  useEffect(() => {
    async function fetchCenterList() {
      try {
        const response = await api.get("/CenterList", { headers: { "Authorization": `Bearer ${localStorage.getItem("token")}` } });
        console.log("CenterList fetched: ", response.data); // Log the response data
        setCenterList(response.data);
      } catch (error) {
        console.error("Error fetching orders:", error);
      }
    }
    fetchCenterList();
  }, []);

  useEffect(() => {
    async function fetchDeliveryList() {
      try {
        const response = await api.get("/DeliveryList", { headers: { "Authorization": `Bearer ${localStorage.getItem("token")}` } });
        console.log("DeliveryList fetched: ", response.data); // Log the response data
        setDeliveryList(response.data);
      } catch (error) {
        console.error("Error delivery orders:", error);
      }
    }
    fetchDeliveryList();
  }, []);


  const resetErrors = () => {
    setErrors({
      purchasedQuantity: false,
      pricePerQty: false,
    });
  };

  const toggleModal = async (order) => {
    setSelectedOrder(order);
    setIsModalOpen((prevState) => {
      if (prevState) {
        resetErrors(); // Reset errors when closing the modal
      }
      return !prevState;
    });

    if (!isModalOpen) { // Only fetch order details when opening the modal
      try {
        setFormData({
          id: '',
          pricePerQty: null,
          weightPerQty: null,
        });

        const response = await api.post(
          `/OrderDetailsbyOrderId?orderId=${order.id}`,
          {}, // Pass an empty object for the request body if there is no body
          {
            headers: {
              "Authorization": `Bearer ${localStorage.getItem("token")}`
            }
          }
        );
        setOrderDetails(response.data);
        setOrderId(order.id);
      } catch (error) {
        console.error("Error fetching order details:", error);
      }
    }
  };


  // const toggleDispatchModal = (order) => {
  //   setSelectedOrder(order); // Set the selected order
  //   setSelectedDeliveryService("");// Reset selected center
  //   setSelectedTrackingId(""); // Reset selected center
  //   setSelectedShippingCost("");// Reset selected center
  //   setSelectedNote("");// Reset selected center
  //   setIsDispatchModalOpen(!isDispatchModalOpen); // Toggle modal
  // };

  const toggleDispatchModal = (order) => {
    setSelectedOrder(order); // Set the selected order

    const formattedShippingCost = order.shippingCost;
    const currentDate = new Date().toISOString().split('T')[0];

    setDispatchData({
      dispatch_Date: order.dispatch_Date || currentDate, // Set current date if no dispatch date
      shopifyDeliveryService: order.shopifyDeliveryService,
      trackingId: order.trackingId,
      shippingCost: formattedShippingCost,
      note: order.note,
    });
    setIsDispatchModalOpen(!isDispatchModalOpen); // Toggle modal
  };

  const toggleCenterModal = (selectedOrder) => {
    // setSelectedOrder(order); // Set the selected order
    setSelectedCenter(selectedOrder.fulfilmentCenterId); // Reset selected center
    setIsCenterModalOpen(!isCenterModalOpen); // Toggle modal

  };



  const handleQuantityChange = (e, index) => {
    const value = e.target.value;
    const newOrderDetails = [...orderDetails];

    // Ensure the purchasedQuantity is not negative and does not exceed the available quantity
    newOrderDetails[index].purchasedQuantity = Math.max(0, Math.min(value, newOrderDetails[index].quantity));
    // Ensure the purchasedQuantity is not negative and does not exceed the available quantity
    //  newOrderDetails[index].pricePerQty = Math.max(0, Math.min(value, newOrderDetails[index].calculateItemTotalPrice));
    // Update status based on purchased quantity
    const status = newOrderDetails[index].purchasedQuantity === newOrderDetails[index].quantity ? "Purchased" : "Pending";
    newOrderDetails[index].status = status;

    setOrderDetails(newOrderDetails);
    setFormData(prevState => ({
      ...prevState,
      purchasedQuantity: newOrderDetails[index].purchasedQuantity
    }));
  };

  const handlePriceChange = (e, index) => {
    const value = e.target.value;
    const newOrderDetails = [...orderDetails];

    // Ensure the purchasedQuantity is not negative and does not exceed the available quantity
    newOrderDetails[index].pricePerQty = value;

    // Ensure the purchasedQuantity is not negative and does not exceed the available quantity
    //newOrderDetails[index].pricePerItem = Math.max(0, Math.min(value, newOrderDetails[index].calculateItemTotalPrice));
    // Update status based on purchased quantity


    setOrderDetails(newOrderDetails);
    // setFormData(prevState => ({
    //     ...prevState,
    //     pricePerQty: newOrderDetails[index].pricePerQty
    // }));
  };

  const handlePriceChange1 = (e, index) => {
    // Clone the localOrderDetails array
    const newOrders = [...localOrderDetails];

    // Check if the index exists in the array
    if (index >= 0 && index < newOrders.length) {
      // Convert the input value to a number
      const pricePerQty = parseFloat(e.target.value);

      // If the parsed value is a valid number, update the price
      if (!isNaN(pricePerQty)) {
        newOrders[index].pricePerQty = pricePerQty;
        setLocalOrderDetails(newOrders);
      } else {
        console.error("Invalid input: not a number");
      }
    } else {
      console.error(`Index ${index} is out of bounds`);
    }
  };

  const calculateItemTotalPrice = (orderDetail) => {
    return (parseFloat(orderDetail.pricePerQty) * parseFloat(orderDetail.purchasedQuantity)).toFixed(2);
  };

  useEffect(() => {
    if (isModalOpen || isDispatchModalOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isModalOpen, isDispatchModalOpen]);

  const sortOrders = (a, b) => {
    if (sortOrder === 'asc') {
      return a.fullName.localeCompare(b.fullName);
    } else if (sortOrder === 'desc') {
      return b.fullName.localeCompare(a.fullName);
    } else {
      // Default sorting order (descending by CreatedAt)
      return new Date(b.CreatedAt) - new Date(a.CreatedAt);
    }
  };

  const sortOrdersByOrderDate = (a, b) => {
    if (sortOrderDate === 'asc') {
      return new Date(a.orderDate) - new Date(b.orderDate);
    } else if (sortOrderDate === 'desc') {
      return new Date(b.orderDate) - new Date(a.orderDate);
    }
    // Default sorting order (descending by CreatedAt)
    return new Date(b.CreatedAt) - new Date(a.CreatedAt);
  };

  // const toggleSortOrderDate = (sortOrderDate) => {
  //   if (sortOrderDate === 'asc') {
  //     return 'desc'; // If current order is ascending, change to descending
  //   } else if (sortOrderDate === 'desc') {
  //     return 'default'; // If current order is descending, change to default
  //   } else {
  //     return 'asc'; // If current order is default, change to ascending
  //   }
  // };

  const toggleSortOrderDate = () => {
    setSortOrderDate((prevOrder) => {
      if (prevOrder === 'asc') {
        return 'desc';
      } else if (prevOrder === 'desc') {
        return 'default';
      } else {
        return 'asc';
      }
    });
  };


  const sortOrdersByOrderName = (a, b) => {
    if (sortOrderName === 'asc') {
      return a.orderName.localeCompare(b.orderName);
    } else if (sortOrderName === 'desc') {
      return b.orderName.localeCompare(a.orderName);
    }
    // Default sorting order (descending by CreatedAt)
    return new Date(b.CreatedAt) - new Date(a.CreatedAt);
  };

  const toggleSortOrderName = (sortOrderName) => {
    if (sortOrderName === 'asc') {
      return 'desc'; // If current order is ascending, change to descending
    } else if (sortOrderName === 'desc') {
      return 'default'; // If current order is descending, change to default
    } else {
      return 'asc'; // If current order is default, change to ascending
    }
  };

  const handleSortOrderNameToggle = () => {
    setSortOrderName(toggleSortOrderName(sortOrderName));
  };


  const filteredOrders = orders
    .filter(order => {
      if (filterOption === 'Center allocated') return order.fulfilmentCenterId !== null && order.status !== "Cancelled";
      if (filterOption === 'Center not-allocated') return order.fulfilmentCenterId === null && order.status !== "Cancelled";
      return true;
    })
    .filter(order => {
      if (filterOption === 'Dispatched') return order.lock_Order === true && order.fulfilmentCenterId !== null && order.status === "Pending";
      if (filterOption === 'Not dispatched') return order.lock_Order === false && order.status === "Pending";
      return true;
    })
    .filter(order => {
      if (filterOption === 'Cancelled') return order.status === 'Cancelled';
      return true;
    })
    .filter(order =>
      order.fullName.toLowerCase().toString().includes(searchQuery.toLowerCase().toString()) ||
      order.orderName.toLowerCase().toString().includes(searchQuery.toLowerCase().toString()) ||
      order.phoneNo && order.phoneNo.toString().includes(searchQuery.toString()) ||
      order.email && order.email.toLowerCase().toString().includes(searchQuery.toLowerCase().toString()) ||
      order.shopifyOrderId && order.shopifyOrderId.toString().includes(searchQuery.toString()) ||
      order.trackingId && order.trackingId.toLowerCase().toString().includes(searchQuery.toLowerCase().toString()) ||
      order.shopifyDeliveryService && order.shopifyDeliveryService.toString().includes(searchQuery.toString()) ||
      order.fullAddress && order.fullAddress.toLowerCase().toString().includes(searchQuery.toLowerCase().toString())
    )
    .sort(sortOrders)
    .sort(sortOrdersByOrderDate)
    .sort(sortOrdersByOrderName);

  const clearSortOrder = () => {
    setFilterOption('');
    setSearchQuery('');
    setSortOption("");
    setSortOrder("");
    setSortOrderDate("");
    setSortOrderName(""); // Reset sorting order to default
    Cookies.remove('filterOption'); // Clear the cookie
    Cookies.remove('sortOption'); // Clear the cookie
    Cookies.remove('searchOrder'); // Clear the cookie
  };

  // const toggleSortOrder = () => {
  //   if (sortOrder === 'asc') {
  //     setSortOrder('desc'); // If current order is ascending, change to descending
  //   } else if (sortOrder === 'desc') {
  //     setSortOrder('default'); // If current order is descending, change to default
  //   } else {
  //     setSortOrder('asc'); // If current order is default, change to ascending
  //   }
  // };

  const toggleSortOrder = () => {
    setSortOrder((prevOrder) => {
      if (prevOrder === 'asc') {
        return 'desc';
      } else if (prevOrder === 'desc') {
        return 'default';
      } else {
        return 'asc';
      }
    });
  };


  const handleSortOrderDateToggle = () => {
    setSortOrderDate(toggleSortOrderDate(sortOrderDate));
  };


  const handleCenterOrder = async () => {
    if (selectedOrder && selectedCenter) {
      try {
        // First, update the shipping order status
        const data = {
          shopifyOrderId: selectedOrder.id,
          centerId: selectedCenter
        };

        const response = await api.post("/OrderFulfillmentCenter", data, { headers: { "Authorization": `Bearer ${localStorage.getItem("token")}` } });
        console.log("Order dispatched:", response.data);

        // Handle success
        // setSelectedOrder((prevOrder) => ({ ...prevOrder, shipped_Flag: true }));
        toast.success("Order Center Added Successfully!");
        setIsCenterModalOpen(false);
        // setSelectedOrder(order);
        setSelectedCenter("");
        refreshOrderList();

      } catch (error) {
        console.error("Error center order:", error);
        // Handle error - maybe show an error message to the user
      }
    } else {
      console.error("Error: Selected order or center is null");
      // Handle missing data error - maybe show an error message to the user
    }
  };

  // Function to refresh the order list or relevant data
  const refreshOrderList = async () => {
    try {
      const response = await api.get("/OrderByCustomer", { headers: { "Authorization": `Bearer ${localStorage.getItem("token")}` } }); // Adjust the API endpoint as needed
      setOrders(response.data); // Assuming you have a state for orders
    } catch (error) {
      console.error("Error refreshing order list:", error);
    }
  };
  // Function to refresh the order list or relevant data
  const refreshOrderDetailList = async (order) => {
    try {
      const response = await api.post(
        `/OrderDetailsbyOrderId?orderId=${order.id}`,
        {}, // Pass an empty object for the request body if there is no body
        {
          headers: {
            "Authorization": `Bearer ${localStorage.getItem("token")}`
          }
        }
      );
      setOrderDetails(response.data);
      setOrderId(order.id);
    } catch (error) {
      console.error("Error refreshing order list:", error);
    }
  };

  const handleDispatchOrder = async (e) => {
    e.preventDefault(); // Prevent form default submission

    if (selectedOrder) {
      // Parse the dates for comparison
      const orderDate = new Date(selectedOrder.orderDate);
      const dispatchDate = new Date(dispatchData.dispatch_Date);

      // Check if dispatch_Date is greater than or equal to orderDate
      if (dispatchDate <= orderDate) {
        toast.error("Dispatch Date cannot be earlier than Order Date.");
        return;
      }

      try {
        // Prepare data for the API request
        const data = {
          id: selectedOrder.id,
          shipped_Flag: true,
          shopifyDeliveryService: dispatchData.shopifyDeliveryService,
          trackingId: dispatchData.trackingId,
          shippingCost: dispatchData.shippingCost,
          note: dispatchData.note,
          orderDate: selectedOrder.orderDate,
          dispatch_Date: dispatchData.dispatch_Date, // Using dispatchData state for the date
        };

        const response = await api.post("/UpdateShippingOrder", data, {
          headers: { "Authorization": `Bearer ${localStorage.getItem("token")}` },
        });

        console.log("Order dispatched:", response.data);

        toast.success("Order Dispatched Successfully!");

        // Update the selected order with the latest data
        setSelectedOrder((prevOrder) => ({
          ...prevOrder,
          shipped_Flag: true,
          orderDate: data.orderDate,
          dispatch_Date: data.dispatch_Date,
          shopifyDeliveryService: data.shopifyDeliveryService,
          trackingId: data.trackingId,
          shippingCost: data.shippingCost,
          note: data.note,
        }));

        // Close the dispatch modal and reset fields
        setIsDispatchModalOpen(false);
        setSelectedCenter("");
        setDispatchData({
          shopifyDeliveryService: '',
          dispatch_Date: '',
          trackingId: '',
          shippingCost: '',
          note: '',
        });

        refreshOrderList();

      } catch (error) {
        toast.error("Error dispatching order");
        console.error("Error dispatching order:", error);
      }
    } else {
      console.error("Error: Selected order or delivery provider is null");
    }
  };


  const userId = localStorage.getItem("id");

  const updateOrderProduct = async (id, purchasedQuantity, productId, pricePerQty, weightInGrams) => {
    if (purchasedQuantity == null || pricePerQty == null || pricePerQty < 0) {
      setErrors({
        purchasedQuantity: purchasedQuantity == null,
        pricePerQty: pricePerQty == null || pricePerQty < 0,
      });
      toast.error('Please enter valid details.');
      return;
    }

    try {
      const updateOrder = {
        userId: userId,
        id: id,
        purchasedQuantity: purchasedQuantity,
        productId: productId,
        pricePerQty: pricePerQty,
        weightInGrams: weightInGrams,
      };

      console.log('Sending update order request:', updateOrder);
      const response = await api.post('/UpdateOrderProductInOrder', updateOrder, { headers: { "Authorization": `Bearer ${localStorage.getItem("token")}` } });
      console.log('Update order response:', response);

      setFormData({
        id: '',
        purchasedQuantity: null,
        pricePerQty: null,
        weightPerQty: null,
        is_Purchased: null
      });

      if (response) {
        toast.success('Updating Order Product Successfully!');
      }

      if (selectedOrder) {
        toggleModal(selectedOrder); // Close and reopen the modal to refresh it
        setIsModalOpen(true);
      }
      refreshOrderList();
      refreshOrderDetailList(selectedOrder);

    } catch (error) {
      console.error('Error updating order product:', error);
      toast.error('Failed to update order product. Please try again later.');
    }
  };


  const fetchCloseOrders = async () => {
    try {
      const response = await api.post(`/CloseOrderWithoutFulfilling?orderId=${orderId}`,
        {}, // Pass an empty object for the request body if there is no body
        {
          headers: {
            "Authorization": `Bearer ${localStorage.getItem("token")}`
          }
        }

      );
      console.log("Orders fetched: ", response.data); // Log the response data
      toast.success('Orders Closed Successfully!');
      // Check if response.data is an array
      if (Array.isArray(response.data)) {
        setOrders(response.data);
        toast.success('Orders Closed Successfully!');
      }
      setOpenCloseModal(false)
      // setOrderDetails(response.data)
      if (selectedOrder) {
        toggleModal(selectedOrder); // Close and reopen the modal to refresh it
        setIsModalOpen(true);
      }

      refreshOrderList();
      refreshOrderDetailList(selectedOrder);
      setOrderId('');
    } catch (error) {
      console.error("Error fetching orders:", error);
      toast.error('Error while closing orders');
    }
  };


  // Pagination logic
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  // Update current page if it exceeds the number of pages after filtering
const pageCount = Math.ceil(filteredOrders.length / itemsPerPage);
if (currentPage > pageCount && pageCount > 0) {
  setCurrentPage(pageCount);
}

  const currentOrders = filteredOrders.filter(order => order.shipped_Flag !== true).slice(startIndex, endIndex);

  const handleChangePage = (event, value) => {
    setCurrentPage(value);
  };

  // const getCardBackgroundColor = () => {
  //   if (order.fulfilmentCenterId !== null) {
  //     // Change color if fulfilmentCenterId is not null
  //     return { bgcolor: '#ffcc80' }; // Example color
  //   }
  //   return {}; // Default or no specific color change
  // };

  useEffect(() => {
    // Retrieve cookie value on component mount
    const savedQuery = Cookies.get('searchOrder');
    if (savedQuery) {
      setSearchQuery(savedQuery);
    }
  }, []);

  const handleSearchChange = (event) => {
    const newSearchQuery = event.target.value;
    setSearchQuery(newSearchQuery);
    // Set cookie with the new search query
    Cookies.set('searchOrder', newSearchQuery, { expires: 7 }); // Expires in 7 days
  };

  useEffect(() => {
    const savedSortOption = Cookies.get('sortOption');
    if (savedSortOption) {
      setSortOption(savedSortOption);
      // Initialize sort order based on saved sort option
      handleSortChange({ target: { value: savedSortOption } });
    }
  }, []);
  const handleSortChange = (event) => {
    const value = event.target.value;
    setSortOption(value);
    Cookies.set('sortOption', value, { expires: 7 }); // Cookie expires in 7 days

    // Call appropriate sorting function based on selected option
    if (value === 'AtoZ') {
      setSortOrder('asc');
    } else if (value === 'ZtoA') {
      setSortOrder('desc');
    } else if (value === 'Newest') {
      setSortOrderDate('asc');
    } else if (value === 'Oldest') {
      setSortOrderDate('desc');
    } else if (value === 'Order No.: Asc') {
      setSortOrderName('asc');
    } else if (value === 'Order No.: Des') {
      setSortOrderName('desc');
    }
  };


  // const handleFilterChange = (event) => {
  //   setFilterOption(event.target.value);
  // };

  useEffect(() => {
    const savedFilterOption = Cookies.get('filterOption');
    if (savedFilterOption) {
      setFilterOption(savedFilterOption);
    }
  }, []);

  const handleFilterChange = (value) => {
    setFilterOption(value);
    Cookies.set('filterOption', value, { expires: 7 }); // Cookie expires in 7 days
  };
  const [errors, setErrors] = useState({
    shopifyDeliveryService: false,
    shippingCost: false,
    trackingId: false,
    note: false,
    purchasedQuantity: false,
    pricePerQty: false,
  });

  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;
  //   setDispatchData({
  //     ...dispatchData,
  //     [name]: value,
  //   });
  // };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setDispatchData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    // Clear the error when the user changes the input
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '',
    }));
  };


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <ToastContainer
        style={{
          position: isMobile ? 'top-right' : 'top-right',
          width: isMobile ? '80%' : '25%',
          fontSize: isMobile ? '12px' : '15px'
        }}
      />
      {/* <MDBox
        // mr={4}
        py={3}
        px={2}
        variant="gradient"
        bgColor="info"
        borderRadius="lg"
        coloredShadow="info"
      >
        <Typography variant="h5" style={{ color: 'white' }} component="h2">
          Order Management
        </Typography>
      </MDBox> */}
      <Box sx={{ display: "flex", flexDirection: isMobile ? "column" : "row", alignItems: "center", mt: 2 }}>
        <TextField
          variant="outlined"
          size="medium"
          placeholder="Search"
          value={searchQuery}
          onChange={handleSearchChange}
          //onChange={(e) => setSearchQuery(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" style={{ fontSize: '20px' }}>
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          sx={{
            width: isMobile ? '100%' : 'auto',
            maxWidth: 240,
            margin: isMobile ? '8px 0' : '4px',
            '& .MuiOutlinedInput-root': {
              borderRadius: '0.375rem',
              '&.Mui-focused fieldset': {
                borderColor: '#3e3e45',
              },
            },
            '& .MuiInputLabel-root': {
              fontSize: '1rem', // Adjust label size
            },
          }}
        />
        <FormControl
          sx={{
            width: isMobile ? '100%' : 'auto',
            maxWidth: 240,
            margin: isMobile ? '8px 0' : '4px',
            '& .MuiOutlinedInput-root': {
              '&.Mui-focused fieldset': {
                borderColor: '#3e3e45', // Outline color when focused
              },
            },
          }}
        >
          <InputLabel id="sort-label" style={{ color: '#3e3e45' }}>Sort by</InputLabel>
          <Select
            labelId="sort-label"
            value={sortOption}
            label="Sort By"
            onChange={handleSortChange}
            sx={{
              width: isMobile ? '100%' : '155px',
              borderRadius: '0.375rem',
              padding: '12px 0px',
              marginBottom: isMobile ? '0px' : '0',
            }}
          >
            <MenuItem value="">
              <em>Sort by</em>
            </MenuItem>
            <MenuItem value="AtoZ">A to Z</MenuItem>
            <MenuItem value="ZtoA">Z to A</MenuItem>
            <MenuItem value="Oldest">Newest</MenuItem>
            <MenuItem value="Newest">Oldest</MenuItem>
            <MenuItem value="Order No.: Asc">OrderNo. : Asc</MenuItem>
            <MenuItem value="Order No.: Des">OrderNo. : Des</MenuItem>
          </Select>
        </FormControl>


        <FormControl
          sx={{
            width: isMobile ? '100%' : 'auto',
            maxWidth: 240,
            margin: isMobile ? '8px 0' : '4px',
            '& .MuiOutlinedInput-root': {
              '&.Mui-focused fieldset': {
                borderColor: '#3e3e45', // Outline color when focused
              },
            },
          }}
        >
          <InputLabel id="filter-label" style={{ color: '#3e3e45' }}>Filter by</InputLabel>
          <Select
            labelId="filter-label"
            value={filterOption}
            label="Filter by"
            //onChange={handleFilterChange}
            onChange={(event) => handleFilterChange(event.target.value)}
            sx={{
              width: isMobile ? '100%' : '165px',
              borderRadius: '0.375rem',
              padding: '12px 0px',
              marginBottom: isMobile ? '0px' : '0',
            }}
          >
            <MenuItem value="">
              <em>Filter by</em>
            </MenuItem>
            <MenuItem value="Center allocated">Center Selected</MenuItem>
            <MenuItem value="Center not-allocated">Center Not Selected</MenuItem>
            <MenuItem value="Dispatched">Ready for Dispatch</MenuItem>
            <MenuItem value="Not dispatched">Not Ready for Dispatch</MenuItem>
            <MenuItem value="Cancelled">Cancel Orders</MenuItem>
          </Select>
        </FormControl>
        <Tooltip title="Clear">
          <Button
            onClick={clearSortOrder}
            variant="contained"
            style={{ color: 'white', backgroundColor: '#3e3e45' }}
            sx={{
              marginLeft: isMobile ? '0' : 'auto',
              marginTop: isMobile ? '10px' : '0',
              marginBottom: isMobile ? '10px' : '0',
              width: isMobile ? '40%' : 'auto',
            }}
          >
            <LuFilterX style={{ fontSize: '20px' }} />
          </Button>
        </Tooltip>

      </Box>
      <Box sx={{ mt: 2 }}>
        <Grid container spacing={3} sx={{ justifyContent: 'center' }}>
          {currentOrders.map((order, index) => (
            <Grid item xs={12} sm={6} md={3} key={index} sx={{ minWidth: '350px' }}>

              <Card sx={{
                height: '100%',
                "&:hover": {
                  backgroundColor: 'rgb(255 255 255 / 57%)',
                  boxShadow: '6px 6px 15px 1px #646464a1'
                },
                //
              }}
              >
                <Tooltip title="View Order Details">
                  <CardActionArea onClick={() => toggleModal(order)}>
                    <CardContent style={{ height: '100%' }}>
                      <MDBox
                        mt='10px'
                        mb='15px'
                        variant="contained"
                        style={{
                          background: order && order.status !== null
                            ? order.status === 'Cancelled'
                              ? 'red'
                              : order.lock_Order === true
                                ? '#10BC10'
                                : order.fulfilmentCenterId !== null ? 'rgb(190, 189, 143)'
                                  : '#3e3e45'
                            : '#3e3e45',
                          borderRadius: "10px",
                          boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
                          width: "100%"
                        }}
                      >
                        <Typography style={{ color: 'white', fontWeight: '1rem' }} align="center">
                          Order No  :{order.orderName}
                        </Typography>
                        <Typography
                          // gutterBottom
                          // variant="h5"
                          // component="div"
                          align="center"
                          style={{ color: 'white' }}
                          sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', maxHeight: '2em' }}
                        >
                          {order.fullName}
                        </Typography>
                      </MDBox>
                      {/* <MDBox
                        // mt='10px'
                        // mb='15px'
                        
                        px='2'
                        variant="contained"
                        style={{
                          marginRight:'auto',
                          background: '#10BC10',
                          borderRadius: "10px",
                          boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
                          width: "fit-content",
                          transform: "rotate(-45deg)", // Rotate the MDBox by 45 degrees
                          transformOrigin: "top left", // Adjust the origin of the rotation
                          position: "relative", // Ensure positioning is relative for better control
                          overflow: "hidden", // Hide overflow to avoid visual issues
                        }}
                      >
                        <Typography
                          align="center"
                          style={{ color: 'white' }}
                        >
                          Center Selected
                        </Typography>
                      </MDBox> */}

                      <Typography align="center" variant="body1" style={{ fontSize: 'large', fontWeight: '500' }} component="div" >
                        Date: {moment(order.orderDate).format('ll')}&nbsp;|&nbsp;Time: {moment(order.orderDate).format('LT')}
                      </Typography>
                      <Typography align="center" variant="body1" style={{ fontSize: 'large', fontWeight: '500' }} component="div" >
                        Total Items: {order.totalItems}&nbsp;|&nbsp;Total Weight : {order.totalWeight} <span style={{ fontSize: 'medium' }}>gm</span>
                      </Typography>
                      <Typography align="center" variant="body1" sx={{ fontSize: 'large', fontWeight: 500 }} component="div">
                        <span style={{ color: 'darkgreen' }}>Purchased: {order.purchasedItems}</span>
                        &nbsp;&nbsp;|&nbsp;&nbsp;
                        {order.lock_Order ? (
                          <span style={{ color: '#e80e0e' }}>Not Available : {order.pendingItems}</span>
                        ) : (
                          <span style={{ color: '#e80e0e' }}>Pending: {order.pendingItems}</span>
                        )}
                      </Typography>
                    </CardContent>

                  </CardActionArea></Tooltip>

                {/* <CardActions sx={{ justifyContent: 'center' }}> */}

                <>
                  <Tooltip title="Dispatch">
                    {order.lock_Order && !order.shipped_Flag && order.fulfilmentCenterId !== null && order.status !== "Cancelled" && (
                      //filter: `drop-shadow(-4px 2px 2px rgb(0 0 0 / 0.4))`
                      <IconButton
                        style={{ color: '#10BC10', textAlign: 'end', fontSize: 'xx-large' }}
                        onClick={() => toggleDispatchModal(order)}
                      >
                        <FaShippingFast />
                      </IconButton>

                    )}
                  </Tooltip>
                </>
                {/* </CardActions> */}

              </Card>

            </Grid>
          ))}
        </Grid>

      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
        <Pagination
          //count={Math.ceil(filteredOrders.filter(order => order.shipped_Flag !== true).length / itemsPerPage)}
          count={pageCount}
          page={currentPage}
          onChange={handleChangePage}
          color="primary"
          sx={{
            '& .MuiPaginationItem-root.Mui-selected': {
              color: '#fff',
              background: 'linear-gradient(195deg, #42424a, #191919)',
            },
          }}
        />
      </Box>
      <Modal open={isModalOpen} onClose={toggleModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: isMobile ? '90%' : 1500,
            maxWidth: '100%',
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 2,
            maxHeight: isMobile ? '80vh' : '90vh',
            overflowY: "auto",
            borderRadius: '20px',
            scrollbarWidth: 'none'
          }}
        >
          <IconButton
            sx={{ position: 'absolute', top: 8, right: 8 }}
            style={{ color: 'black', fontWeight: '500' }}
            onClick={toggleModal}
          >
            <CloseIcon />
          </IconButton>
          <div style={{ display: 'flex', flexDirection: 'row' }} >
            <Typography variant="body1" color='black' style={{ margin: '10px', fontWeight: '500', fontSize: '25px' }} component="div" >
              Order Details
              {isModalOpen ? (
                <Typography>
                  Order No: {selectedOrder.orderName}
                </Typography>
              ) : null}

              {isModalOpen ? (
                <Typography>
                  Name: {selectedOrder.fullName}
                </Typography>
              ) : null}

            </Typography>

            <Typography variant="body1" color='black' style={{ marginLeft: 'auto', marginTop: '64px', fontWeight: '500', fontSize: '25px' }} component="div" >
              <Tooltip title="Not Available Order">
                <Button variant="contained" style={{ margin: '10px', color: 'white', textTransform: 'none', backgroundColor: '#3e3e45', textAlign: 'end', marginLeft: 'auto' }} onClick={() => setOpenCloseModal(true)}>
                  Mark as Not Available
                </Button>
              </Tooltip>
              <Tooltip title="Select Center">
                <Button variant="contained" style={{ color: 'white', backgroundColor: '#3e3e45', textAlign: 'center' }} onClick={() => toggleCenterModal(selectedOrder)}>
                  <BiSolidLocationPlus />
                </Button>
              </Tooltip>
            </Typography>
          </div>
          {isMobile ? (
            <Swiper spaceBetween={10} slidesPerView={1}>
              {orderDetails.map((orderDetail, index) => (
                <SwiperSlide key={index}>
                  <Box sx={{
                    padding: 2,
                    border: '1px solid #ddd',
                    borderRadius: '10px',
                    mb: 2,
                    bgcolor: '#f9f9f9' // Light background for better contrast
                  }}>
                    <Typography variant="h6" sx={{ mb: 1 }}>Item No: {index + 1}</Typography>

                    <Typography sx={{ mb: 1 }}>
                      <strong>Product:</strong> {orderDetail.productName}
                    </Typography>

                    <Typography sx={{ mb: 1 }}>
                      <strong>Qty:</strong> {orderDetail.quantity}
                    </Typography>

                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                      <Typography sx={{ mr: 1 }}><strong>Purchased Qty:</strong></Typography>
                      <TextField
                        type="number"
                        value={orderDetail.purchasedQuantity || ""}
                        sx={{ width: '80px', margin: 0 }}
                        inputProps={{ min: 0 }}
                        onChange={(e) => handleQuantityChange(e, index)}
                        required
                      />
                    </Box>

                    <Typography sx={{ mb: 1 }}>
                      <strong>Weight In Grams:</strong> {orderDetail.weightInGrams}
                    </Typography>

                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                      <Typography sx={{ mr: 1 }}><strong>Price Per Qty:</strong></Typography>
                      <TextField
                        type="number"
                        value={orderDetail.pricePerQty || ""}
                        sx={{ width: '80px', margin: 0 }}
                        inputProps={{ min: 0, pattern: "[0-9]*" }}
                        onChange={(e) => handlePriceChange(e, index)}
                        required
                      />
                    </Box>

                    <Typography sx={{ mb: 1 }}>
                      <strong>Total Weight:</strong> {orderDetail.purchasedQuantity * orderDetail.weightInGrams} gm
                    </Typography>

                    <Typography sx={{ mb: 1 }}>
                      <strong>Total Price:</strong> ₹{orderDetail.pricePerQty * orderDetail.purchasedQuantity}
                    </Typography>

                    <Typography sx={{ mb: 1 }}>
                      <strong>Status:</strong> {orderDetail.is_Purchased ? "Purchased" : orderDetail.isNotAvailable ? "N/A" : orderDetail.notAvailableQty ? `N/A ${orderDetail.notAvailableQty} Qty` : "Pending"}
                    </Typography>

                    <Typography sx={{ mb: 1 }}>
                      <strong>Action:</strong>
                      <Tooltip title="Update Order Product">
                        <IconButton
                          style={{ color: 'black' }}
                          onClick={() => updateOrderProduct(orderDetail.id, orderDetail.purchasedQuantity, orderDetail.productId, orderDetail.pricePerQty, orderDetail.weightInGrams)}
                          disabled={orderDetail.is_Purchased}
                        >
                          <IoMdCheckboxOutline />
                        </IconButton>
                      </Tooltip>
                    </Typography>
                  </Box>

                </SwiperSlide>
              ))}
            </Swiper>
          ) : (
            <>
              <TableContainer component={Paper} sx={{ maxHeight: 600, overflow: 'auto', scrollbarWidth: 'none' }}>
                <Table stickyHeader>
                  <TableBody>
                    <TableRow sx={{ position: 'sticky', zIndex: 1, top: 0, backgroundColor: '#3e3e45', color: 'white' }}>
                      <TableCell style={{ fontWeight: "400", color: 'white' }}>Sr No</TableCell>
                      <TableCell style={{ fontWeight: "400", color: 'white' }}>Product</TableCell>
                      <TableCell style={{ fontWeight: "400", color: 'white' }} align="center">Qty</TableCell>
                      <TableCell style={{ fontWeight: '400', color: 'white' }} align="center">Purchased Qty</TableCell>
                      <TableCell style={{ fontWeight: "400", color: 'white' }} align="center">Weight In Grams</TableCell>
                      <TableCell style={{ fontWeight: '400', color: 'white' }} align="center">Price Per Qty</TableCell>
                      <TableCell style={{ fontWeight: "400", color: 'white' }} align="center">Total Weight</TableCell>
                      <TableCell style={{ fontWeight: "400", color: 'white' }} align="center">Total Price</TableCell>
                      <TableCell style={{ fontWeight: "400", color: 'white' }} align="center">Status</TableCell>
                      <TableCell style={{ fontWeight: "400", color: 'white' }} align="center">Action</TableCell>
                    </TableRow>
                    {orderDetails.map((orderDetail, index) => (
                      <TableRow key={index}>
                        <TableCell style={{ fontWeight: "500" }}> {index + 1}.</TableCell>
                        <TableCell style={{ fontWeight: "500" }}> {orderDetail.productName}</TableCell>
                        <TableCell style={{ fontWeight: "500" }} align="center">{orderDetail.quantity}</TableCell>
                        <TableCell align="center">
                          <TextField
                            type="number"
                            value={orderDetail.purchasedQuantity || ""}
                            style={{ width: '60px', margin: '0px', padding: '0', boxSizing: 'border-box' }}
                            onChange={(e) => handleQuantityChange(e, index)}
                            required
                            InputProps={{ style: { fontWeight: '500' } }}
                          />
                        </TableCell>
                        <TableCell style={{ fontWeight: "500" }} align="center">{orderDetail.weightInGrams}</TableCell>
                        <TableCell>
                          <TextField
                            type="number"
                            value={orderDetail.pricePerQty || ""}
                            inputProps={{ min: 0, pattern: "[0-9]*" }}
                            style={{ width: '80px', margin: '0px', padding: '0', boxSizing: 'border-box' }}
                            onChange={(e) => handlePriceChange(e, index)}
                            required
                            InputProps={{ style: { fontWeight: '500' } }}
                          />
                        </TableCell>
                        <TableCell style={{ fontWeight: "500" }} align="center">
                          {orderDetail.purchasedQuantity * orderDetail.weightInGrams}
                          <span style={{ fontSize: 'medium' }}>gm</span>
                        </TableCell>
                        <TableCell style={{ fontWeight: "500" }} align="center">
                          ₹{orderDetail.pricePerQty * orderDetail.purchasedQuantity}
                        </TableCell>
                        <TableCell align="center">
                          <TableCell
                            sx={{
                              width: 'max-content',
                              borderRadius: '4px',
                              display: 'flex',
                              alignItems: 'center',
                              padding: '4px 8px',
                              fontSize: '13px',
                              color: orderDetail.is_Purchased ? '#008000' : '#ff0000',
                              backgroundColor: orderDetail.is_Purchased ? 'rgba(0, 128, 0, 0.2)' : 'rgba(255, 0, 0, 0.2)',
                            }}
                          >
                            <span
                              style={{
                                width: '4px',
                                height: '4px',
                                borderRadius: '50%',
                                marginRight: '4px',
                                backgroundColor: orderDetail.is_Purchased ? '#008000' : '#ff0000',
                              }}
                            ></span>
                            {orderDetail.is_Purchased ? "Purchased" : orderDetail.isNotAvailable ? "N/A" : orderDetail.notAvailableQty ? `N/A ${orderDetail.notAvailableQty} Qty` : "Pending"}
                            {/* {orderDetail.is_Purchased ? "Purchased" : orderDetail.notAvailableQty ? `N/A ${orderDetail.notAvailableQty} Qty` : "Pending"} */}
                          </TableCell>
                        </TableCell>
                        <TableCell align="center">
                          {/* {orderDetail.is_Purchased !== true && ( */}
                            <Tooltip title="Update Order Product">
                              <IconButton
                                style={{ margin: '0px', color: 'black' }}
                                onClick={() => updateOrderProduct(orderDetail.id, orderDetail.purchasedQuantity, orderDetail.productId, orderDetail.pricePerQty, orderDetail.weightInGrams)}
                                // disabled={orderDetail.is_Purchased}
                              >
                                <IoMdCheckboxOutline />
                              </IconButton>
                            </Tooltip>
                          {/* )} */}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
          {/* <Tooltip title="Not Available Order">
            <Button variant="contained" style={{ margin: '10px', color: 'white',backgroundColor:'#3e3e45' ,textAlign: 'end', marginLeft: 'auto' }} onClick={() => setOpenCloseModal(true)}>
              Mark as Not Available
            </Button>
          </Tooltip>
          <Tooltip title="Select Center">
            <Button variant="contained" style={{ color: 'white',backgroundColor:'#3e3e45', textAlign: 'center' }} onClick={() => toggleCenterModal(selectedOrder)}>
              <BiSolidLocationPlus />
            </Button>
          </Tooltip> */}
        </Box>
      </Modal>
      <Modal
        open={openCloseModal}
        onClose={() => setOpenCloseModal(false)}
        aria-labelledby="vendor-modal-title"
        aria-describedby="vendor-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: isMobile ? '90%' : 600,
            maxWidth: '100%',
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 2,
            maxHeight: isMobile ? '80vh' : '90vh',
            overflowY: "auto",
          }}
        >
          <MDBox
            mx={2}
            py={1}
            px={1}
            variant="gradient"
            bgColor='dark'
            borderRadius="lg"
            coloredShadow="info"
          >
            {/* <IconButton
            sx={{ position: 'absolute', top: 8, right: 8 }}
            style={{ color: 'black', fontWeight: 'bold' }}
            onClick={() => setOpenCloseModal(false)}
          >
            <CloseIcon />
          </IconButton> */}
            <Typography variant="body1" style={{ color: 'white' }} component="div" align="center" >
              Close Order Details
            </Typography>

          </MDBox>
          <Typography variant="body1" style={{ color: 'black', margin: '10px' }} component="div" align="center" >
            Are you sure you want to Close Order ?
          </Typography>

          <Box sx={{ display: 'flex', justifyContent: 'space-evenly', mt: 2 }}>
            <Button variant="contained" style={{ color: "white", backgroundColor: '#212644fc' }} onClick={() => fetchCloseOrders()}>
              Confirm Order
            </Button>
            <Button variant="contained" style={{ color: "black", backgroundColor: '#ccc' }} onClick={() => setOpenCloseModal(false)}>
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>

      <Modal open={isDispatchModalOpen && !!selectedOrder} onClose={toggleDispatchModal}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: isMobile ? '90%' : 400,
            maxWidth: '100%',
            bgcolor: 'background.paper',
            boxShadow: 24,
            borderRadius: 4,
            p: 4,
            maxHeight: '80vh',
            overflowY: 'auto',
          }}
        >
          <IconButton
            sx={{ position: 'absolute', top: 8, right: 8 }}
            style={{ color: 'black', fontWeight: '500' }}
            onClick={toggleDispatchModal}
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h5" style={{ color: 'black' }} align="center" sx={{ mb: 2 }}>
            Dispatch Order
          </Typography>
          {selectedOrder && (
            <>
              <Typography variant="body1" gutterBottom>
                Total Weight: {selectedOrder.totalWeight} gm
              </Typography>
            </>
          )}
          {/* <FormControl fullWidth sx={{ mb: 2 }} style={{ marginTop: '5px', maxHeight: '200px' }}>
            <InputLabel id="center-select-label">Select Delivery Provider</InputLabel>
            <Select
              labelId="center-select-label"
              value={selectedDeliveryProvider}
              label="Select Delivery Provider"
              style={{ padding: '10px 0' }}
              onChange={(e) => setSelectedDeliveryProvider(e.target.value)}
            >
              {DeliveryList.map((deliver, index) => (
                <MenuItem key={index} value={deliver.id}>
                  {deliver.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl> */}
          <form onSubmit={handleDispatchOrder}>
            <TextField
              label="Delivery Provider"
              name="shopifyDeliveryService"
              value={dispatchData.shopifyDeliveryService}
              onChange={handleInputChange}
              variant="outlined"
              fullWidth
              margin="normal"
            // required
            // error={!!errors.shopifyDeliveryService}
            // helperText={errors.shopifyDeliveryService}
            />
            {/* <TextField
              type="date"
              label="Dispatch Date"
              name="dispatch_Date" // Make sure this matches the state property name
              value={dispatchData.dispatch_Date}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
              required
              error={!!errors.dispatch_Date}
              helperText={errors.dispatch_Date}
              InputLabelProps={{
                style: { color: 'black' },
                shrink: true,
              }}
            /> */}
            <TextField
              type="date"
              label="Dispatch Date"
              name="dispatch_Date" // Make sure this matches the state property name
              value={dispatchData.dispatch_Date}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
              required
              error={!!errors.dispatch_Date} // Shows error state if there's an error
              helperText={errors.dispatch_Date} // Displays the error message
              InputLabelProps={{
                style: { color: 'black' },
                shrink: true,
              }}
            />


            <TextField
              label="Tracking Id"
              name="trackingId"
              value={dispatchData.trackingId}
              onChange={handleInputChange}
              variant="outlined"
              fullWidth
              margin="normal"
            // required
            // error={!!errors.trackingId}
            // helperText={errors.trackingId}
            />
            <TextField
              type="number"
              label="Shipping Cost"
              name="shippingCost"
              value={dispatchData.shippingCost}
              onChange={handleInputChange}
              variant="outlined"
              fullWidth
              margin="normal"
              inputProps={{ min: 0 }}
              required
              error={!!errors.shippingCost}
              helperText={errors.shippingCost}
            />
            <TextField
              label="Note"
              name="note"
              value={dispatchData.note}
              onChange={handleInputChange}
              variant="outlined"
              fullWidth
              margin="normal"
              multiline
              rows={4} // Adjust the number of rows as needed
              error={!!errors.note}
              helperText={errors.note}
            />
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
              
                <Button
                  variant="contained"
                  style={{ color: "white", backgroundColor: '#212644fc', textAlign: 'end' }}
                  type="submit"
                >
                  Confirm Dispatch
                </Button>
            </div>
          </form>
        </Box>
      </Modal>
      <Modal open={isCenterModalOpen && !!selectedOrder} onClose={toggleCenterModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: isMobile ? '90%' : 400,
            maxWidth: '100%',
            bgcolor: "background.paper",
            boxShadow: 24,
            borderRadius: 4,
            p: 4,
            maxHeight: '80vh',
            overflowY: "auto",
          }}
        >
          {/* <IconButton
            sx={{ position: 'absolute', top: 8, right: 8 }}
            style={{ color: 'black', fontWeight: 'bold' }}
            onClick={toggleCenterModal}
          >
            <CloseIcon />
          </IconButton> */}
          <Typography variant="h5" component="div" gutterBottom align="center" sx={{ mb: 2 }}>
            Center
          </Typography>
          {/* Display Order ID and Total Weight */}
          {selectedOrder && ( // Ensure selectedOrder is not null
            <>
              {/* <Typography variant="body1" gutterBottom>
                Dasher: {selectedOrder.userName}
              </Typography> */}
            </>
          )}
          <FormControl fullWidth sx={{ mb: 2 }} style={{ marginTop: '5px', maxHeight: '200px' }}>
            <InputLabel id="center-select-label">Select Center</InputLabel>
            <Select
              labelId="center-select-label"
              value={selectedCenter}
              label="Select Center"
              style={{ padding: '10px 0' }}
              onChange={(e) => setSelectedCenter(e.target.value)}
            >
              {CenterList.map((center, index) => (
                <MenuItem key={index} value={center.id}>
                  {center.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Tooltip title="Confirm Center">
            <Button
              variant="contained"
              style={{ color: "white", backgroundColor: '#212644fc' }}
              onClick={() => handleCenterOrder(orderDetails.id)}
            >
              Confirm Center
            </Button>
          </Tooltip>
        </Box>
      </Modal>
      {/* <Footer dark /> */}
    </DashboardLayout>
  );
};

export default Orders;